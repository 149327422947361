import React from "react";
import ReactMarkdown from "react-markdown";
import { WhatsApp, Email } from "icons/icons";

export default function JobCarouselCard(props) {
  const [readMore, setReadMore] = React.useState([false, false]);

  // 0 is when both are closed
  // 1 is when first(above) is open
  // 2 is when second(below) is open
  return (
    <div className="mobile_container">
      {props.data[0] && !readMore[1] ? (
        <div
          key={props.data[0].id}
          className={`showcase_item ${readMore[0] ? "enlarge" : ""} ${
            props.job?.id === props.data[0].id &&
            props.job?.appID === props.data[0].appID
              ? "selectCard"
              : ""
          }`}
          style={{ display: readMore[1] ? "none" : "" }}
        >
          <div className="showcase_item_body">
            {/* <div
              className="showcase_item_body_carousel client-carousel-card-article"
              id="showcase_item_body_carousel"
            > */}
            {readMore[0] ? (
              <>
                <div className="more">
                  <ReactMarkdown>
                    {props.data[0].projectdescription}
                  </ReactMarkdown>
                </div>
                <div className={"apply_popover "}>
                  <div
                    className={
                      "apply_popover--links " +
                      (props.popover ? " " : "display--none")
                    }
                  >
                    <a
                      target={"_blank"}
                      href="https://wa.me/919867910690"
                      rel="noopener noreferrer"
                    >
                      <WhatsApp className={"navbar-icon"} />
                    </a>
                    <a
                      target={"_blank"}
                      href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=KoyoSoftwareSolutions@gmail.com#"
                      rel="noreferrer noopener"
                    >
                      <Email className={"navbar-icon"} />
                    </a>
                  </div>

                  <button
                    className="apply_popover--button"
                    onClick={props.handleClick}
                  >
                    Apply Now
                  </button>
                </div>
              </>
            ) : (
              <div className="main_details">
                <span className="title">{props.data[0].topic}</span>
                <span className="category2">
                  <span className="head">Keywords:</span>
                  {props.data[0].keywords}
                </span>
                <span className="category2">
                  <span className="head">ID:</span>
                  {props.data[0].appID}
                </span>
                <span className="category2">
                  <span className="head">Deadline:</span>
                  {props.data[0].date}
                </span>
                <span className="category2">
                  <span className="head">Duration:</span>
                  {props.data[0].duration}
                </span>
                <span className="desc">
                  <span>{props.data[0].projectdescription.slice(0, 150)}</span>
                  <strong>...</strong>
                </span>
              </div>
            )}
            <span className="share">
              <a
                href={
                  "whatsapp://send?text=" +
                  `${window.location.origin}/?wt=${props.Worktype.replace(
                    " ",
                    "-"
                  )}%26wc=${props.Workcategory.replace(" ", "-")}%26jid=${
                    props.data[0].id
                  }%26AppId=${props.data[0].appID}`
                }
                data-action="share/whatsapp/share"
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <i className="fa-solid fa-share-nodes"></i>
              </a>
            </span>
          </div>
          <div onClick={() => setReadMore([!readMore[0], 0])} className="read">
            Read {readMore[0] ? "Less" : "More"}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* second client box */}
      {props.data[1] && !readMore[0] ? (
        <div
          key={props.data[1].id}
          className={`showcase_item ${readMore[1] ? "enlarge" : ""}
          ${
            props.job?.id === props.data[1].id &&
            props.job?.appID === props.data[1].appID
              ? "selectCard"
              : ""
          }`}
        >
          <div className="showcase_item_body">
            {readMore[1] ? (
              <>
                <div className="more">
                  <ReactMarkdown>
                    {props.data[1].projectdescription}
                  </ReactMarkdown>
                </div>
                <div className={"apply_popover "}>
                  <div
                    className={
                      "apply_popover--links " +
                      (props.popover ? " " : "display--none")
                    }
                  >
                    <a
                      target={"_blank"}
                      href="https://wa.me/919867910690"
                      rel="noopener noreferrer"
                    >
                      <WhatsApp className={"navbar-icon"} />
                    </a>
                    <a
                      target={"_blank"}
                      href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=KoyoSoftwareSolutions@gmail.com#"
                      rel="noreferrer noopener"
                    >
                      <Email className={"navbar-icon"} />
                    </a>
                  </div>

                  <button
                    className="apply_popover--button"
                    onClick={props.handleClick}
                  >
                    Apply Now
                  </button>
                </div>
              </>
            ) : (
              <div className="main_details">
                <span className="title">{props.data[1].topic}</span>
                <span className="category2">
                  <span className="head">Keywords:</span>
                  {props.data[1].keywords}
                </span>
                <span className="category2">
                  <span className="head">ID:</span>
                  {props.data[1].appID}
                </span>
                <span className="category2">
                  <span className="head">Deadline:</span>
                  {props.data[1].date}
                </span>
                <span className="category2">
                  <span className="head">Duration:</span>
                  {props.data[1].duration}
                </span>
                <span className="desc">
                  <span>{props.data[1].projectdescription.slice(0, 150)}</span>
                  <strong>...</strong>
                </span>
              </div>
            )}
            <span className="share">
              <a
                href={
                  "whatsapp://send?text=" +
                  `${window.location.origin}/?wt=${props.Worktype}%26wc=${props.Workcategory}%26jid=${props.data[1].id}%26AppId=${props.data[1].appID}`
                }
                data-action="share/whatsapp/share"
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <i className="fa-solid fa-share-nodes"></i>
              </a>
            </span>
          </div>
          <div onClick={() => setReadMore([0, !readMore[1]])} className="read">
            Read {readMore[1] ? "Less" : "More"}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
