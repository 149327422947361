import { useState, useEffect, useRef } from "react";
import { HashLink } from "react-router-hash-link";

import Dropdown from "./Dropdown";
import { WhatsApp, Email, Hamburger } from "icons/icons";
import "./Navbar.css";

export default function Navbar() {
  const [toggleActive, setToggleActive] = useState(false);
  const [clickToggle, setClickToggle] = useState(false);
  const [visible, setVisible] = useState(true);
  // In mobile view if ham button is clicked
  const [sidebarDisplay, setSidebarDisplay] = useState(false);
  const previousPosition = useRef();
  // Mobile view to show or hide sideBar
  function handleClick(e) {
    setSidebarDisplay((state) => !state);
  }
  // Mobile view closes the side bar
  function closeSidebar() {
    setSidebarDisplay(false);
  }
  const toggleNavbar = () => {
    const currentPosition = window.scrollY;
    var navHeight = 60;
    // console.log(currentPosition, previousPosition.current);
    if (
      currentPosition < navHeight ||
      currentPosition <= previousPosition.current
    ) {
      setVisible(true);
      previousPosition.current = currentPosition;
    } else {
      setVisible(false);
      previousPosition.current = currentPosition;
    }
  };
  useEffect(() => {
    previousPosition.current = window.scrollY;
    window.addEventListener("scroll", toggleNavbar);
    return () => window.addEventListener("scroll", toggleNavbar);
  }, []);
  return (
    <>
      <nav className={`navbar-container ${visible ? "show" : ""}`}>
        <div className={"fixed-container-size navbar"}>
          <div className={"navbar-links-container"}>
            <div onClick={handleClick} className={"hamburger-icon"}>
              <Hamburger className={""} />
            </div>
            <img
              className={"navbar-logo"}
              src="assets/img/navbar/koyo-logo.png"
              alt="Logo"
            />

            {/* UPDATE HASH LINKS HERE IN FUTURE */}
            <ul className={"navbar-links"}>
              <li>
                <HashLink smooth to="/#home">
                  Home
                </HashLink>
              </li>
              <li>
                <Dropdown
                  closeSidebar={closeSidebar}
                  toggleActive={toggleActive}
                  setToggleActive={setToggleActive}
                  clickToggle={clickToggle}
                  setClickToggle={setClickToggle}
                />
              </li>
              <li>
                <HashLink smooth to="/#Jobarea">
                  Jobs
                </HashLink>
              </li>

              <li>
                <HashLink smooth to="/#Pricing">
                  Pricing
                </HashLink>
              </li>
              <li>
                <HashLink to="/#contact">Contact</HashLink>
              </li>
            </ul>
          </div>

          <div className={"navbar-controls"}>
            <a
              target={"_blank"}
              href="https://wa.me/919867910690"
              rel="noopener noreferrer"
            >
              <WhatsApp className={"navbar-icon"} />
            </a>

            <a
              target={"_blank"}
              href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=KoyoSoftwareSolutions@gmail.com#"
              rel="noreferrer noopener"
            >
              <Email className={"navbar-icon"} />
            </a>
          </div>
        </div>

        <ul className={`navbar-links-mobile ${sidebarDisplay ? "active" : ""}`}>
          <li onClick={handleClick}>
            <HashLink smooth to="/#home">
              Home
            </HashLink>
          </li>
          <li>
            <Dropdown
              closeSidebar={closeSidebar}
              toggleActive={toggleActive}
              setToggleActive={setToggleActive}
              clickToggle={clickToggle}
              setClickToggle={setClickToggle}
            />
          </li>
          <li onClick={handleClick}>
            <HashLink smooth to="/#Jobarea">
              Jobs
            </HashLink>
          </li>

          <li onClick={handleClick}>
            <HashLink smooth to="/#Pricing">
              Pricing
            </HashLink>
          </li>
          <li onClick={handleClick}>
            <HashLink to="/#contact">Contact</HashLink>
          </li>
        </ul>
      </nav>
      {sidebarDisplay || toggleActive || clickToggle ? (
        <div
          className="navbar-space"
          onClick={() => {
            setSidebarDisplay(false);
            setToggleActive(false);
            setClickToggle(false);
          }}
        ></div>
      ) : (
        ""
      )}
    </>
  );
}

/*

    Further dev guide,
    DONT WORRY ABOUT ALL THESE HANDLERS LIKE handleClick THEY ARE MAINLY FLAGS FOR MOBILE VIEW

    Most of the work here will be just to add or replace, hash links or links to already available options!

    All the responsibilites of dropdown is present in Dropdown component

*/
