import React from "react";
import AboutDesktop from "./AboutDesktop";
import AboutMobile from "./AboutMobile";
import "./About.css";

export default function About() {
  return (
    <section id="team" className="about-team team">
      <div
        className="checkteam-container aos-init aos-animate section_container"
        data-aos="fade-up"
      >
        <div className="section_head_container">
          <div className="section-title">Team</div>
          <p className="section-heading">Check Our Team</p>
        </div>

        <div className="teamdesktop">
          <AboutDesktop />
        </div>
        <div className="teammobile">
          <AboutMobile />
        </div>
      </div>
    </section>
  );
}
