import React from "react";
import Slider from "react-slick";
import JobCarouselCard from "./JobCarouselCard";
import "./jobsCarousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import jobdata from "../JobsData";
import { useEffect, useRef } from "react";
import { useState } from "react";

export default function JobCarousel({
  Worktype,
  Workcategory,
  popover,
  job,
  handleClick,
}) {
  //  settings for slick slider which we are using below
  var settings = {
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    autoplay: false,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    pauseOnHover: true,
    // slidesPerRow: 1,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       rows: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       rows: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 400,
    //     settings: {
    //       rows: 1,
    //     },
    //   },
    // ],
  };

  // ClientContentdata is passed as a prop which is having a seperate file
  const jobSlider = useRef();
  const [rowsData, setRowsData] = useState([]);
  useEffect(() => {
    const filtered = jobdata.filter(
      (data) =>
        data.jobFilterLevel1 === Worktype &&
        data.jobFilterLevel2 === Workcategory
    );
    if (job) {
      const index = filtered.findIndex((i) => i.id === job.id);
      jobSlider.current.slickGoTo(Math.floor(index + 1 / 2));
    }
    // console.log(filtered);

    // const rowsData = filtered?.reduce(function (rows, key, index) {
    //   console.log("r:", rows);
    //   return (
    //     (index % 2 === 0
    //       ? rows?.push([key])
    //       : rows[rows.length - 1]?.push(key)) && rows
    //   );
    // });

    //converting 1d array to 2d array
    const row = [];
    for (let i = 0; i < filtered.length; i++) {
      if (i % 2 === 0 && filtered[i + 1]) {
        row.push([filtered[i], filtered[i + 1]]);
        ++i;
      } else row.push([filtered[i]]);
    }
    setRowsData(row);
  }, [Worktype, Workcategory, job]);
  // useEffect(()=>{

  // },[job])

  return (
    <>
      <div className="main-carousel">
        <div id="ourclients">
          <div className="SlideContent-client">
            {
              <Slider
                {...settings}
                ref={jobSlider}
                // custom paging here used to customize the dots
              >
                {rowsData.map((data) => (
                  <JobCarouselCard
                    data={data}
                    key={data[0].id}
                    popover={popover}
                    Worktype={Worktype}
                    Workcategory={Workcategory}
                    handleClick={handleClick}
                    job={job}
                  />
                ))}
              </Slider>
            }
          </div>
        </div>
      </div>
    </>
  );
}
