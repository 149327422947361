import ComingSoon from "components/Home/Header/ComingSoon";
import React, { useState } from "react";
import ReactModal from "react-modal";
import { HashLink } from "react-router-hash-link";
import "./Dropdown.css";
function Dropdown(props) {
  // const setActive = () => {
  //   setToggleActive(true);
  // };

  const {
    setToggleActive,
    toggleActive,
    closeSidebar,
    clickToggle,
    setClickToggle,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const setInActive = () => {
    // setToggleActive(!toggleActive);
    setClickToggle(!clickToggle);
  };
  function openModal(val) {
    setIsOpen(true);
    setName(val);
  }
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div onClick={isOpen ? closeModal : null}>
        <ReactModal
          onRequestClose={closeModal}
          shouldCloseOnEsc={true}
          isOpen={isOpen}
          className="popUpRoot"
          overlayClassName="popUpOverlay"
          shouldCloseOnOverlayClick={true}
        >
          <ComingSoon title={name} onClick={closeModal} />
        </ReactModal>
      </div>
      <div className="nav-services-dropdown">
        <div
          className="dropdown"
          onMouseEnter={() => {
            if (window.screen.width > 600) setToggleActive(true);
          }}
          onMouseLeave={() => {
            if (window.screen.width > 600) setToggleActive(false);
          }}
          onClick={setInActive}
        >
          <a
            className="dropdown-toggle"
            href="/"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Services
          </a>

          <ul
            // style={toggleActive ? { display: "block" } : { display: "none" }}
            className={`dropdown-menu ${
              toggleActive || clickToggle ? "show" : ""
            } `}
            aria-labelledby="dropdownMenuLink"
          >
            <li>
              <HashLink
                onClick={() => {
                  closeSidebar();
                }}
                smooth
                to="/#ourwebsites"
              >
                Web Design
              </HashLink>
            </li>
            <li>
              <HashLink
                onClick={
                  !isOpen
                    ? () => {
                        openModal("AI & ML");
                        closeSidebar();
                      }
                    : null
                }
                smooth
                to="/#"
              >
                AI & ML
              </HashLink>
            </li>
            <li>
              <HashLink
                onClick={
                  !isOpen
                    ? () => {
                        openModal("eCommerce");
                        closeSidebar();
                      }
                    : null
                }
                smooth
                to="/#"
              >
                eCommerce
              </HashLink>
            </li>
            <li>
              <HashLink
                onClick={() => {
                  closeSidebar();
                }}
                smooth
                to="#graphicsportfolio"
              >
                Branding Solutions
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Dropdown;
