import React from "react";
import "./switch.css";

export default function Switch({ toggle, setToggle }) {
  return (
    <span
      className={`cc_slider round ${toggle ? "active" : ""}`}
      onClick={() => setToggle(!toggle)}
    ></span>
  );
}
