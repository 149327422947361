import React from "react";
import "./Card.css";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import { styled } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";
// import ClickAwayListener from "@mui/material/ClickAwayListener";
import ReactTooltip from "react-tooltip";
function Card({ data, choose, slide, isMobile }) {
  const { slideIndex, setSlideIndex } = slide;

  // const [choosed, setChoose] = useState(false);
  // useEffect(() => {
  //   if (slideIndex === data.id) setChoose(true);
  //   else setChoose(false);
  // }, [slideIndex]);
  // const [open, setOpen] = React.useState(false);

  // const handleTooltipClose = () => {
  //   setOpen(false);
  // };

  // const handleTooltipOpen = () => {
  //   setOpen(true);
  // };

  // const HtmlTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: "#f5f5f9",
  //     color: "rgba(0, 0, 0, 0.87)",
  //     maxWidth: 220,
  //     fontSize: theme.typography.pxToRem(12),
  //     border: "1px solid #dadde9",
  //   },
  // }));
  // const tip = {
  //   margin: "0",
  // };
  const formatName = (name) => {
    var len = 30;
    if (isMobile) {
      if (String(name).length > len) return String(name).slice(0, len) + "...";
      return name;
    }
    return name;
  };
  return (
    <div
      id="pcard"
      className={`card ${slideIndex === data.id ? "choosed" : "idle"}  `}
      onClick={() => setSlideIndex(data.id)}
    >
      <div className="name">{formatName(data.name)}</div>
      <div className="price">
        {data.currency}
        {choose
          ? Number(data.annually).toLocaleString("en-US")
          : Number(data.monthly).toLocaleString("en-US")}
      </div>
      <div className="specs">
        {/* {data.benefits.map((d, index) => (
          <HtmlTooltip
            key={Math.random() * 1000}
            style={tip}
            arrow
            title={
              <>
                <Typography fontSize={16} fontWeight={500}>
                  {data.benefits[index]}
                </Typography>
                <Typography fontSize={11} fontWeight={500}>
                  {data.tip[index]}
                </Typography>
              </>
            }
            placement="right"
          >
            <span className="benefits">{data.benefits[index]}</span>
          </HtmlTooltip>
        ))} */}
        {data.benefits.map((d, index) => (
          <span
            key={Math.random() * 100}
            className="benefits"
            data-tip
            data-for={`cc_${data.id}_${index}`}
            // data-event="click"
          >
            {data.benefits[index]}
          </span>
        ))}
        {data.benefits.map((d, index) => (
          <ReactTooltip
            key={Math.random() * 100}
            id={`cc_${data.id}_${index}`}
            place="top"
            effect="solid"
            className="tp"
            afterShow={() => {
              if (isMobile) setSlideIndex(data.id);
            }}
          >
            <span className="tp_head1"> {data.benefits[index]}</span>
            <span className="tp_head2"> {data.tip[index]}</span>
          </ReactTooltip>
        ))}
      </div>
      {/* <button
        className={`choose_btn ${slideIndex === data.id ? "choosed" : ""}`}
      > */}
      <a
        className={`choose_btn ${slideIndex === data.id ? "choosed" : ""}`}
        href={
          "whatsapp://send?text=" +
          `${window.location.origin}/?pid=${data.id}%26name=${data.name.replace(
            " ",
            "-"
          )}%26Annual=${choose}`
        }
        data-action="share/whatsapp/share"
        target={"_blank"}
        rel="noopener noreferrer"
      >
        Choose
      </a>
      {/* </button> */}
    </div>
  );
}

export default Card;

// import PropTypes from 'prop-types';
// import styled, { css } from 'styled-components';
// import { SwitcherContext } from '../context';
// import Button from './Button';
//
// const StyledCardWrapper = styled.div`
//   display: inline-grid;
//   width: 280px;
//   grid-gap: 15px;
//   padding: 27px 25px;
//   border-radius: 10px;
//   background-color: ${({ theme }) => theme.colors.white};
//   box-shadow: ${({ theme }) => theme.shadow};
//   @media ${({ theme }) => theme.breakpoints.lg} {
//     width: 315px;
//     grid-gap: 20px;
//     padding: 27px 28px;
//   }
//   ${({ awarded }) =>
//     awarded &&
//     css`
//       background-image: ${({ theme }) => theme.colors.gradient};
//       color: ${({ theme }) => theme.colors.white};
//       padding: 30px 25px;
//       @media ${({ theme }) => theme.breakpoints.lg} {
//         padding: 49px 28px;
//         box-shadow: none;
//       }
//     `}
//     ${({ roundedLeft }) =>
//       roundedLeft &&
//       css`
//         @media ${({ theme }) => theme.breakpoints.lg} {
//           border-radius: 10px 0 0 10px;
//         }
//       `}
//
//     ${({ roundedRight }) =>
//       roundedRight &&
//       css`
//         @media ${({ theme }) => theme.breakpoints.lg} {
//           border-radius: 0 10px 10px 0;
//         }
//       `}
// `;
//
// const StyledName = styled.h3`
//   font-size: ${({ theme }) => theme.fontSizes.l};
//   text-align: center;
// `;
//
// const StyledPrice = styled.span`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-weight: 700;
//   letter-spacing: -2px;
//   font-size: ${({ theme }) => theme.fontSizes.xxxl};
//   color: ${({ theme }) => theme.colors.darkGary};
//   @media ${({ theme }) => theme.breakpoints.lg} {
//     letter-spacing: 0;
//   }
//   ${({ awarded }) =>
//     awarded &&
//     css`
//       color: ${({ theme }) => theme.colors.white};
//     `}
// `;
//
// const StyledCurrency = styled.span`
//   font-size: ${({ theme }) => theme.fontSizes.xxl};
//   margin-right: 5px;
//   @media ${({ theme }) => theme.breakpoints.lg} {
//     margin-right: 8px;
//   }
// `;
//
// const StyledBenefitsWrapper = styled.div`
//   display: grid;
//   grid-gap: 1px;
//   margin-top: 5px;
//   @media ${({ theme }) => theme.breakpoints.lg} {
//     grid-gap: 3px;
//   }
// `;
//
// const StyledBenefit = styled.span`
//   font-size: ${({ theme }) => theme.fontSizes.s};
//   text-align: center;
//   padding: 14px 0;
//   font-weight: 700;
//   border-top: 1px solid ${({ theme }) => theme.colors.lighGary};
//   &:last-child {
//     border-bottom: 1px solid ${({ theme }) => theme.colors.lighGary};
//   }
//   @media ${({ theme }) => theme.breakpoints.lg} {
//     font-size: ${({ theme }) => theme.fontSizes.m};
//   }
// `;
//
// const Card = ({ awarded, roundedLeft, roundedRight, content }) => {
//   const { name, monthly, annually, currency, benefits } = content;
//   return (
//     <SwitcherContext.Consumer>
//       {(switcher) => (
//         <StyledCardWrapper awarded={awarded} roundedLeft={roundedLeft} roundedRight={roundedRight}>
//           <StyledName>{name}</StyledName>
//           <StyledPrice awarded={awarded}>
//             <StyledCurrency>{currency}</StyledCurrency>
//             {switcher.checked ? monthly : annually}
//           </StyledPrice>
//           <StyledBenefitsWrapper>
//             {benefits.map((item) => (
//               <StyledBenefit key={item}>{item}</StyledBenefit>
//             ))}
//           </StyledBenefitsWrapper>
//           <Button awarded={awarded}>Learn more</Button>
//         </StyledCardWrapper>
//       )}
//     </SwitcherContext.Consumer>
//   );
// };
//
// Card.propTypes = {
//   awarded: PropTypes.bool,
//   roundedLeft: PropTypes.bool,
//   roundedRight: PropTypes.bool,
//   content: PropTypes.shape(),
// };
//
// Card.defaultProps = {
//   awarded: false,
//   roundedLeft: false,
//   roundedRight: false,
//   content: {
//     name: '-',
//     monthly: '-',
//     annually: false,
//     currency: '-',
//     benefits: ['-', '-', '-'],
//   },
// };
//
// export default Card;
