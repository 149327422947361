import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import styles from "./ImageViewer.module.css";
// import "./GraphicsImageViewer.css";

export default function GraphicsImageViewer({
  closeImageViewer,
  imageViewDetails,
  winSize,
}) {
  const history = useHistory();

  // If user closes the image viewer
  function handleClose() {
    closeImageViewer();
    history.push("/");
  }
  const imageRef = useRef();
  const [resolution, setResolution] = useState({ w: 0, h: 0 });
  const [loading, setLoading] = useState(true);
  const heightBasedResolution = (cWidth, cHeight, ratio, f = 1) => {
    var imgH = cHeight / f;
    var imgW = imgH * ratio;
    var div = f;

    while (imgW > cWidth) {
      // console.log("wid");
      div += 0.1;
      imgH = cHeight / div;
      imgW = imgH * ratio;
    }
    // console.log("afh:", cHeight, ratio, imgW, imgH);
    return [imgW, imgH];
  };
  const widthBasedResolution = (cWidth, cHeight, ratio, f = 1) => {
    var imgW = cWidth / f;
    var imgH = imgW / ratio;
    var div = f;

    while (imgH > cHeight) {
      // console.log("higher");
      div += 0.1;
      imgW = cWidth / div;
      imgH = imgW / ratio;
    }

    // console.log("afw:", cWidth, ratio, imgW, imgH);
    return [imgW, imgH];
  };
  const initResolution = () => {
    setLoading(true);
    var container = document.querySelector("#imageViewer");

    if (!container || !imageRef?.current?.naturalHeight) return;

    var ratio = imageRef.current.naturalWidth / imageRef.current.naturalHeight;

    var imgH = 0,
      imgW = 0,
      padH = 20,
      padW = 20;

    // console.log(
    //   "------org:",
    //   imageRef.current.naturalWidth,
    //   imageRef.current.naturalHeight
    // );
    if (window.innerWidth > 600) {
      padH = 40;
      padW = 40;
    }
    var cHeight = container.offsetHeight - padH;
    var cWidth = container.offsetWidth - padW;
    // console.log(cWidth, cHeight, padW, padH);
    imgH = imageRef.current.naturalHeight;
    imgW = imageRef.current.naturalWidth;
    if (
      cHeight < imageRef.current.naturalHeight &&
      cWidth < imageRef.current.naturalWidth
    )
      [imgW, imgH] = heightBasedResolution(cWidth, cHeight, ratio);
    else if (cWidth < imageRef.current.naturalWidth)
      [imgW, imgH] = widthBasedResolution(
        cWidth,
        imageRef.current.naturalHeight,
        ratio
      );
    else if (cHeight < imageRef.current.naturalHeight) {
      // console.log("t3");
      [imgW, imgH] = widthBasedResolution(
        imageRef.current.naturalWidth,
        cHeight,
        ratio
      );
    }

    // console.log("new: ", imgW, imgH, ratio);
    if (imgW && imgH) {
      setResolution({ w: imgW, h: imgH });
      setLoading(false);
    }
  };
  useEffect(() => {
    const init = setTimeout(initResolution, 200);
    return () => clearTimeout(init);
  }, [winSize, imageRef?.current?.naturalWidth]); //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "visible");
  }, []);

  return (
    <section className={`${styles.graphics_image_viewer}`} id="imageViewer">
      <div className={`${styles.graphics_img_viewer_container}`}>
        <div onClick={handleClose} className={`${styles.btn_close}`}>
          <img
            src="/assets/img/misc/close.png"
            alt="close"
            className="closee"
          />
        </div>

        <img
          ref={imageRef}
          className={styles.graphics_image}
          src={imageViewDetails.imagePath}
          alt="img"
          style={{
            height: resolution.h ? resolution.h : "100%",
            width: resolution.w ? resolution.w : "100%",
            opacity: loading ? "0" : "1",
          }}
          onLoad={initResolution}
        />
      </div>
      <ImageDescription imageViewDetails={imageViewDetails} loading={loading} />
    </section>
  );
}

export const ImageDescription = ({ imageViewDetails, loading }) => {
  return (
    <>
      {!loading ? (
        <div className={styles.des_container}>
          <div
            // onClick={() => {
            //   if (window.innerWidth <= 600) {
            //     const para = document.getElementById("para");
            //     para.style.display = "block";
            //   }
            // }}
            className={`${styles.graphics_image_details}`}
          >
            <div className={`${styles.graphics_image_viewer_title}`}>
              <div className={`${styles.title}`}>
                <h3>{imageViewDetails.name}</h3>
              </div>
              <a
                href={"whatsapp://send?text=" + imageViewDetails.sharingLink}
                data-action="share/whatsapp/share"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/img/misc/share_whatsapp.png"
                  alt="whatsapp"
                  // height="25px"
                  // width="25px"
                  className="graphicsocial"
                />
              </a>
              <a
                href={`https://t.me/share/url?url=${imageViewDetails.sharingLink}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/img/misc/share_telegram.png"
                  alt="telegram"
                  // height="25px"
                  // width="25px"
                  className="graphicsocial"
                />
              </a>
            </div>

            <p id="para" className="graphics-image-description">
              {imageViewDetails.description}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
