import React from "react";
import AboutData from "./AboutData";
import PersonCardWrapper from "./PersonCardWrapper";

export default function AboutDesktop() {
  return (
    <div className="row">
      {AboutData.map((person) => (
        <PersonCardWrapper key={Math.random() * 1000} person={person} />
      ))}
    </div>
  );
}
