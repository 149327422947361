import React, { useState, useRef, useEffect, useMemo } from "react";
// import styled from 'styled-components';
import Card from "./Card";
import "./Pricing.css";
import PriceData from "./PriceData.json";
// import Switch from "@mui/material/Switch";
import Switch from "./switch/Switch";
import Slider from "react-slick";
// import ScrollContainer from "react-indiana-drag-scroll";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./customCarousel.css";
import PricingTable from "./PricingTable";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
// import { createTheme, ThemeProvider } from "@mui/material/styles";

const Pricing = ({ refProp }) => {
  // const [choose, setChoose] = useState(2);

  const [checked, setChecked] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0); //eslint-disable-line
  const { search } = useLocation();
  // const history = useHistory();

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  const [isMobile, setIsMobile] = useState();
  const [count, setCount] = useState(0); //eslint-disable-line
  const ismobileLayout = () => {
    if (window.innerWidth <= 600) setIsMobile(true);
    else setIsMobile(false);
    // console.log("resize...");
  };
  const handleSelectionDetails = () => {
    const data = PriceData.filter((i) => i.id === slideIndex)[0];
    // console.log(data.name);
    return data;
  };
  const slideInfo = useMemo(() => handleSelectionDetails(), [slideIndex]); //eslint-disable-line react-hooks/exhaustive-deps

  const settings = {
    speed: 500,
    responsive: [
      {
        breakpoint: 2880,
        settings: {
          infinite: false,
          slidesToShow: 5,
          slidesToScroll: 5,
          variableWidth: true,

          dots: false,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 4,
          variableWidth: true,

          dots: false,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          variableWidth: true,

          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          variableWidth: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: false,
          slidesToScroll: 1,
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const sliderRef = useRef();

  const goToSlide = (id) => {
    var slideId = id;
    if (isMobile) slideId /= 2;
    sliderRef.current.slickGoTo(Math.floor(slideId));
  };
  useEffect(() => {
    ismobileLayout();
    window.addEventListener("resize", ismobileLayout);
    return () => window.removeEventListener("resize", ismobileLayout);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const initializePack = () => {
    const query = queryString.parse(search);
    // console.log(query);
    var pid = parseInt(query?.pid);
    if (pid >= 0) {
      console.log("price init");
      var extraval = 0;

      if (isMobile) {
        extraval = window.innerHeight * 0.6;
        pid = pid / 2;
      }
      if (count === 0) {
        setSlideIndex(parseInt(query?.pid));
        sliderRef.current.slickGoTo(Math.floor(pid));
      }

      var scrollY = refProp.current.offsetTop + extraval;
      console.log(pid, extraval, scrollY);
      if (query.Annual === "false") setChecked(false);

      window.scrollTo(0, scrollY);
    }
    setCount(count + 1);
  };
  useEffect(() => {
    // && isNaN(slideIndex)
    if (typeof isMobile === "boolean" && count < 2) initializePack();
  }, [isMobile, refProp?.current?.offsetTop]); //eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   var index = slideIndex;
  //   if (!isMobile) {
  //     sliderRef.current.slickGoTo(Math.floor(index));
  //     // index = index / 2;
  //   }
  //   // console.log(index);
  // }, [slideIndex, isMobile]); //eslint-disable-line react-hooks/exhaustive-deps
  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: "#c2780a",
  //     },
  //   },
  // });
  return (
    <section id="Pricing" className="Pricing" ref={refProp}>
      <div className="pricing_section section_container">
        <div className="section_head_container">
          <div className="section-title">Pricing Component</div>
          <p className="section-heading">Our pricing</p>
          <div className="section-description"></div>
        </div>

        <div className="carousel_title">
          <div className="head1">Plans</div>
          <div className="head2">
            All plans include free migrations and a hack fix guarantee.
          </div>
        </div>

        {/* <ScrollContainer className="pricing_section_cards scroll-container"> */}
        <div id="pricing_carousel">
          {!isMobile ? (
            <div className="carousel">
              <Slider {...settings} ref={sliderRef}>
                {PriceData.map((d) => (
                  <div className="card_wrapper" key={Math.random() * 1000}>
                    <Card
                      isMobile={isMobile}
                      slide={{ slideIndex, setSlideIndex, slideInfo }}
                      data={d}
                      choose={checked}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="carousel">
              <Slider {...settings} ref={sliderRef}>
                {PriceData.map((d, index) => {
                  if (index % 2 === 0 && PriceData[index + 1]) {
                    return (
                      <div
                        key={Math.random() * 1000}
                        className="card_wrapper mobile"
                      >
                        <Card
                          isMobile={isMobile}
                          slide={{ slideIndex, setSlideIndex, slideInfo }}
                          data={d}
                          choose={checked}
                        />
                        <Card
                          isMobile={isMobile}
                          slide={{ slideIndex, setSlideIndex, slideInfo }}
                          data={PriceData[index + 1]}
                          choose={checked}
                        />
                      </div>
                    );
                  } else if (index % 2 === 0 && !PriceData[index + 1]) {
                    return (
                      <div
                        className="card_wrapper mobile"
                        key={Math.random() * 1000}
                      >
                        <Card
                          isMobile={isMobile}
                          slide={{ slideIndex, setSlideIndex, slideInfo }}
                          data={d}
                          choose={checked}
                        />
                      </div>
                    );
                  }
                  return "";
                })}
              </Slider>
            </div>
          )}
        </div>
        {/* </ScrollContainer> */}
        <div className="switch_container">
          <span className="switch_container_span">
            <strong>Monthly</strong>
          </span>
          {/* <ThemeProvider theme={theme}>
            <Switch
              checked={checked}
              onChange={handleChange}
              color="primary"
              inputProps={{ "aria-label": "controlled" }}
            />
          </ThemeProvider> */}
          <Switch toggle={checked} setToggle={setChecked} />
          <span className="switch_container_span">
            <strong>Annually</strong>
          </span>
        </div>
        <div className="table_title">
          <div className="head1">All plan details</div>
        </div>
        <PricingTable
          slide={{ slideIndex, setSlideIndex, goToSlide }}
          choose={checked}
          isMobile={isMobile}
        />
      </div>
    </section>
  );
};

export default Pricing;
