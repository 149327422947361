import React, { useState, useRef } from "react";
import priceData from "./PriceData.json";
import "./pricetable.css";
import { InView } from "react-intersection-observer";
// import queryString from "query-string";

export default function PricingTable({ choose, slide }) {
  const { slideIndex, setSlideIndex, goToSlide } = slide;
  const rowTitle = [
    "WordPress installs",
    "Monthly visits",
    "SSD storage",
    "Free CDN",
    "Cloudflare Enterprise",
    "30-day money back guarantee",
    "Free premium migrations",
    "Backup retention",
    "Monthly visits",
    "SSD storage",
    "Free CDN",
    "Cloudflare Enterprise",
    "Backup retention",
    "Monthly visits",
    "SSD storage",
    "Free CDN",
    "Cloudflare Enterprise",
  ];
  const wordPress = [...priceData.map((i) => i.WordPress_installs)];
  const m_visits = [...priceData.map((i) => i.Monthly_visits)];
  const ssd = [...priceData.map((i) => i.SSD_storage)];
  const cdn = [...priceData.map((i) => i.Free_CDN)];
  const enterprise = [...priceData.map((i) => i.Cloudflare_Enterprise)];
  const money_back = [...priceData.map((i) => i.money_back_guarantee)];
  const premium_migrations = [...priceData.map((i) => i.Free_premium_migrations)];
  const Backup = [...priceData.map((i) => i.Backup_retention)];
  const rowValues = [
    wordPress,
    m_visits,
    ssd,
    cdn,
    enterprise,
    money_back,
    premium_migrations,
    Backup,
    m_visits,
    ssd,
    cdn,
    enterprise,
    Backup,
    m_visits,
    ssd,
    cdn,
    enterprise,
  ];

  // React.useEffect(() => {
  //   const table = document.querySelector(".p_table");
  //   table.addEventListener("wheel", (e) => {
  //     console.log(e.deltaY);
  //     e.preventDefault();
  //     table.scrollLeft += e.deltaY;
  //   });
  // }, []);

  const moveTableLeft = () => {
    const table = document.querySelector(".p_table");
    var length = table.clientWidth;
    table.scrollLeft -= length * 0.5;
  };
  const moveTableRight = () => {
    const table = document.querySelector(".p_table");
    // console.log(table.scrollLeft, table.clientWidth);
    var length = table.clientWidth;
    // const arrowR = document.querySelector(".p_table .right-arrow");
    // const arrowL = document.querySelector(".p_table .left-arrow");
    // if (table.scrollLeft >= table.clientWidth) {
    //   arrowR.classList.add("disable");
    //   arrowL.classList.remove("disable");
    //   return;
    // }
    // arrowL.classList.remove("disable");
    // arrowR.classList.remove("disable");
    table.scrollLeft += length * 0.5;
    // if (table.scrollLeft >= table.clientWidth) {
    //   arrowR.classList.add("disable");
    //   arrowL.classList.remove("disable");
    // }
  };
  const [windowSize, setWindowSize] = useState();
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      // console.log(window.screen.width);
      setWindowSize(window.innerWidth);
    });
    return () =>
      window.removeEventListener("resize", () => {
        setWindowSize(window.innerWidth);
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InView threshold={0} rootMargin={"0px 0px -200px 0px"}>
      {({ inView, ref }) => {
        // handleTooltips();

        // console.log(ref.current);
        if (inView) {
          // console.log(inView);
          // const table = document.querySelector(".p_table");
          // var bodyRect = document.body.getBoundingClientRect();
          // var offset = table.getBoundingClientRect().top - bodyRect.top - 60;
          // window.scrollTo({ top: Math.round(offset) });
        }
        return (
          <div className="p_table" ref={ref}>
            <table>
              <thead>
                <tr>
                  <td>
                    <button className="left-arrow" onClick={moveTableLeft}>
                      <i className="fa-solid fa-caret-left"></i>
                    </button>
                    <button className="right-arrow" onClick={moveTableRight}>
                      <i className="fa-solid fa-caret-right"></i>
                    </button>
                  </td>
                  {priceData.map((data) => (
                    <td
                      key={data.id}
                      className={slideIndex === data.id ? "choosed" : ""}
                      onClick={() => {
                        goToSlide(data.id);
                        setSlideIndex(data.id);
                      }}
                    >
                      <Pack
                        data={data}
                        windowSize={windowSize}
                        choose={choose}
                      />
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rowTitle?.map((d, index) => (
                  <tr key={Math.random() * 1000 * 0.4}>
                    <td key={Math.random() * 1000 * 5}>
                      <Service name={rowTitle[index]} windowSize={windowSize} />
                    </td>
                    {rowValues[index]?.map((row, index) => {
                      if (typeof row === "boolean")
                        return (
                          <td
                            key={Math.random() * 1000 * 6}
                            className={slideIndex === index ? "choosed" : ""}
                            onClick={() => {
                              goToSlide(index);
                              setSlideIndex(index);
                            }}
                          >
                            {row ? <span>&#10004;</span> : <span></span>}
                          </td>
                        );
                      else
                        return (
                          <td
                            key={Math.random() * 1000 * 7}
                            className={slideIndex === index ? "choosed" : ""}
                            onClick={() => setSlideIndex(index)}
                          >
                            {row}
                          </td>
                        );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }}
    </InView>
  );
}

const Service = ({ name, windowSize }) => {
  const ref = useRef();
  const [tooltip, setTooltip] = useState(false);
  React.useEffect(() => {
    if (ref.current.offsetWidth < ref.current.children[0].scrollWidth)
      setTooltip(true);
    else setTooltip(false);
  }, [windowSize]);
  return (
    <div
      ref={ref}
      className={`services ${tooltip ? "show" : ""}`}
      data-i={name}
    >
      <div>{name}</div>
    </div>
  );
};

const Pack = ({ data, choose, windowSize }) => {
  const ref = useRef();
  const [tooltip, setTooltip] = useState(false);
  React.useEffect(() => {
    if (ref.current.offsetWidth < ref.current.children[0].scrollWidth)
      setTooltip(true);
    else setTooltip(false);
  }, [windowSize]);
  return (
    <div className="info">
      <span
        ref={ref}
        className={`pt_name ${tooltip ? "show" : ""}`}
        data-i={data.name}
      >
        <div>{data.name}</div>
      </span>
      <span className="pt_price">
        {data.currency}
        {choose
          ? Number(data.annually).toLocaleString("en-US")
          : Number(data.monthly).toLocaleString("en-US")}
      </span>
      <button>
        <a
          href={
            "whatsapp://send?text=" +
            `${window.location.origin}/?pid=${
              data.id
            }%26name=${data.name.replace(" ", "-")}%26Annual=${choose}`
          }
          data-action="share/whatsapp/share"
          target={"_blank"}
          rel="noopener noreferrer"
        >
          Choose
        </a>
      </button>
    </div>
  );
};
