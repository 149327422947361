import React, { useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import "./jobContent.css";
import Jobdata from "./JobsData";
import { WhatsApp, Email } from "icons/icons";
import "./Jobs.css";

const JobContent = ({ Worktype, Workcategory, handleClick, popover, job }) => {
  const myref = useRef(null);

  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [content, setContent] = useState({});

  //  function to handle readmore and we are passing id here
  const readMoreHandler = (id) => {
    if (readMoreClicked === id) {
      return setReadMoreClicked(null);
    }
    const Contentdata = Jobdata.find((data) => data.id === id);
    setContent(Contentdata);
    setReadMoreClicked(id);
  };

  return (
    <div className="job_card_container" ref={myref}>
      {Jobdata.map(
        (data, index) =>
          data.jobFilterLevel1 === Worktype &&
          data.jobFilterLevel2 === Workcategory && (
            <div
              key={index}
              className={`showcase_item ${
                job?.id === data.id && job?.appID === data.appID
                  ? "selectCard"
                  : ""
              }`}
            >
              <div className="showcase_item_body">
                {content.id === data.id && readMoreClicked ? (
                  <>
                    <div className="more">
                      <ReactMarkdown>
                        {content.projectdescription}
                      </ReactMarkdown>
                    </div>
                    <div className={"apply_popover "}>
                      <div
                        className={
                          "apply_popover--links " +
                          (popover ? " " : "display--none")
                        }
                      >
                        <a
                          target={"_blank"}
                          href="https://wa.me/919867910690"
                          rel="noopener noreferrer"
                        >
                          <WhatsApp className={"navbar-icon"} />
                        </a>
                        <a
                          target={"_blank"}
                          href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=KoyoSoftwareSolutions@gmail.com#"
                          rel="noreferrer noopener"
                        >
                          <Email className={"navbar-icon"} />
                        </a>
                      </div>

                      <button
                        className="apply_popover--button"
                        onClick={handleClick}
                      >
                        Apply Now
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="main_details">
                    <span className="title">{data.topic}</span>
                    <span className="category2">
                      <span className="head">Keywords:</span>
                      {data.keywords}
                    </span>
                    <span className="category2">
                      <span className="head">ID:</span>
                      {data.appID}
                    </span>
                    <span className="category2">
                      <span className="head">Deadline:</span>
                      {data.date}
                    </span>
                    <span className="category2">
                      <span className="head">Duration:</span>
                      {data.duration}
                    </span>
                    <span className="desc">
                      <span>{data.projectdescription.slice(0, 150)}</span>
                      <strong>...</strong>
                    </span>
                  </div>
                )}
                <span className="share">
                  <a
                    href={
                      "whatsapp://send?text=" +
                      `${window.location.origin}/?wt=${Worktype.replace(
                        " ",
                        "-"
                      )}%26wc=${Workcategory.replace(" ", "-")}%26jid=${
                        data.id
                      }%26AppId=${data.appID}`
                    }
                    data-action="share/whatsapp/share"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className="fa-solid fa-share-nodes"></i>
                  </a>
                </span>
              </div>

              <div onClick={() => readMoreHandler(data.id)} className="read">
                Read {readMoreClicked === data.id ? "Less" : "More"}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default JobContent;
